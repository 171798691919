const appConfig = {
    apiPrefix: 'https://vehicle-emi-recovery-server.onrender.com/api/v1/',
    // apiPrefix: 'http://localhost:5000/api/v1/web/',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: true,
}

export default appConfig
